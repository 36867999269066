import NotFound from '@presentation/pages/NotFound';
import DefaultLayout from '@presentation/layouts/DefaultLayout';

export { Page };

function Page() {
	return (
		<DefaultLayout>
			<NotFound />
		</DefaultLayout>
	);
}
